<template>
	<div class="mx-4">
		<div class="overflow-y" style="max-height: calc(100vh - 250px)">
			<v-layout class="border-bottom-light-grey min-height-57px">
				<v-flex class="font-level-3-bold my-auto d-flex justify-space-between">
					<div>
						<!--begin::Svg Icon-->
						<inline-svg
							style="width: 20px; height: 20px"
							fill="#006fc8"
							:src="$assetURL('media/custom-svg/invoice_1.svg')"
						/>
						<!--end ::Svg Icon-->
						<span class="ml-4">Invoice</span>
					</div>
					<div>
						<v-btn
							@click="openInvoiceDialog"
							color="blue darken-4 mr-4 white--text"
							class="ml-4"
							tile
							depressed
							v-if="getPermission('project-invoice::create')"
						>
							<v-icon>mdi-plus</v-icon>Create</v-btn
						>
					</div>
				</v-flex>
			</v-layout>
			<v-simple-table
				v-if="!pageLoading"
				class="bt-table listing-table table-head-sticky"
				style="width: 100%"
			>
				<thead>
					<tr>
						<th width="10px">#</th>
						<th>Invoice No</th>
						<th>Title</th>
						<!-- <th>Proforma No</th> -->
						<th>Amount</th>
						<th width="150px">Invoice Date</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody v-if="invoiceListing.length">
					<tr v-for="(row, index) in invoiceListing" :key="index + 1">
						<td width="10px">{{ index + 1 }}</td>

						<td width="120px">
							<Chip tooltip tooltip-text="Invoice No" :text="row.barcode" color="cyan darken-4"> </Chip>
							<div>
								<Chip
									class="mt-1"
									tooltip
									tooltip-text="Proforma No"
									:text="row.proforma_barcode"
									color="cyan darken-4"
								>
								</Chip>
							</div>
						</td>
						<td width="300px" class="text-capitalize truncate-text">
							<ShowValue :object="row" object-key="title" label="Tittle"></ShowValue>
						</td>
						<td width="250px">
							<div>
								<span class="fw-500">Raised Amount: </span>
								<ShowPrice :object="row" object-key="amount" label="amount"></ShowPrice>
							</div>

							<div>
								<span class="fw-500">Paid : </span>
								<ShowPrice :object="row" object-key="paid_amount" label="Paid"></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Balance : </span>
								<ShowPrice :object="row" object-key="balance" label="Balance"></ShowPrice>
							</div>
						</td>

						<td>
							<span v-if="row.date" class="fw-500 no-wrap">
								{{ formatDate(row.date) }}
							</span>
							<span v-else>
								<em class="text-muted">no date</em>
							</span>
						</td>
						<td>
							<Chip
								tooltip
								tooltip-text="Status"
								:text="row.status_value"
								:color="
									row.status_value === 'unpaid' ? 'red' : row.status_value === 'paid' ? 'green' : 'orange'
								"
							>
							</Chip>
							<v-btn
								@click="createInvoicePayment(row)"
								v-if="row.status_value !== 'paid'"
								color="blue darken-4 white--text"
								class="ml-4"
								tile
								depressed
								small
							>
								Pay Now</v-btn
							>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no Proforma at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
			<div v-else class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</div>
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue";
import ShowPrice from "@/view/components/ShowPrice";
import Chip from "@/view/components/Chip";
import { getInvoiceByProjectMilestone } from "@/core/lib/project.lib";
import { SET_ERROR /* SET_MESSAGE */ } from "@/core/services/store/common.module";
import ListingMixin from "@/core/mixins/listing.mixin";
export default {
	name: "milestone-detail",
	title: "Milestone Detail",
	mixins: [ListingMixin],
	data() {
		return {
			invoiceListing: [],
		};
	},
	props: {
		project: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		Chip,
		ShowValue,
		ShowPrice,
	},
	methods: {
		openInvoiceDialog() {
			this.$router.push({
				name: "create-invoice",
				params: { uuid: this.project.uuid },
				query: { mileUUID: this.$route.query.mileUUID, t: new Date().getTime() },
			});
		},
		createInvoicePayment(invoice) {
			this.$router.push({
				name: "create-payment",
				params: { uuid: this.$route.params.uuid },
				query: { mileUUID: this.$route.query.mileUUID, invoiceID: invoice.id, t: new Date().getTime() },
			});
		},
		async invoiceInit() {
			const _this = this;
			try {
				this.pageLoading = true;
				const { invoices } = await getInvoiceByProjectMilestone(_this.$route.query.mileUUID);
				this.invoiceListing = invoices;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},
	async mounted() {
		this.internal = true;
		await this.invoiceInit();
	},
};
</script>
