<template>
	<v-sheet class="project" style="height: calc(100vh - 88px)">
		<template>
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<div class="d-flex pb-3 justify-space-between">
						<div class="d-flex pb-3 align-items-center">
							<p class="my-auto font-level-3-bold text-truncate" :style="`font-size: 20px`">
								<ShowValue :object="milestone" object-key="phase" label="Milestone Name"> </ShowValue>
							</p>
							<v-chip
								v-if="milestone?.status_value"
								label
								:color="`${getStatusColor(
									milestone?.status_value
								)} white--text text--darken-4 text-capitilize`"
								class="ml-2"
							>
								<span class="text-capitalize">
									{{ milestone?.status_value }}
								</span>
							</v-chip>
						</div>
						<div class="d-flex align-items-center">
							<v-btn
								depressed
								v-if="getPermission('project-milestone::edit')"
								tile
								class="blue darken-4 mr-2 my-auto"
								color="white--text "
								@click="editMilestone"
							>
								<v-icon class="mr-1">mdi-pencil</v-icon>
								Edit
							</v-btn>
							<v-menu
								bottom
								left
								origin="center center"
								transition="slide-y-transition"
								rounded="0"
								offset-y
								max-height="400px"
								close-on-content-click
								content-class="white-background"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn color="blue darken-4 text-white mr-2" v-on="on" v-bind="attrs" depressed tile
										><v-icon small left>mdi-more mdi-flip-h</v-icon>More</v-btn
									>
								</template>
								<v-list class="py-0">
									<!-- <v-list-item v-on:click="changingMilestoneStatus('draft')">
										<v-list-item-title>
											<v-icon left color="orange">mdi-file-outline</v-icon>
											<span class="orange--text">Draft</span></v-list-item-title
										>
									</v-list-item> -->
									<v-list-item v-on:click="changingMilestoneStatus('pending')">
										<v-list-item-title>
											<v-icon left color="red lighten-1">mdi-cancel</v-icon>
											<span class="red--text text--lighten-1"> Pending </span>
										</v-list-item-title>
									</v-list-item>
									<v-divider class="m-0 p-0"></v-divider>
									<v-list-item v-on:click="changingMilestoneStatus('completed')">
										<v-list-item-title>
											<v-icon left color="green">mdi-check-all</v-icon>
											<span class="green--text">Completed</span></v-list-item-title
										>
									</v-list-item>
									<!-- <v-divider class="m-0 p-0"></v-divider> -->
								</v-list>
							</v-menu>
							<div class="mr-2">
								<v-btn v-on:click="goBack()" color="" class="ml-2 fw-500" depressed tile>
									<v-icon small left>mdi-arrow-left</v-icon>Back
								</v-btn>
							</div>
						</div>
					</div>
					<div class="d-flex">
						<div class="pb-3 w-33">
							<div>
								<span class="fw-600">Project # :</span>
								<v-chip v-if="project?.barcode" color="cyan" class="ml-2" label text-color="white">{{
									project.barcode
								}}</v-chip>
							</div>
							<div class="my-3">
								<span class="fw-600">Project Title :</span>
								<span class="fw-500">
									<ShowValue class="ml-2" :object="project" object-key="name" label="Name"> </ShowValue>
								</span>
							</div>
						</div>
						<div class="d-flex pb-3 w-33">
							<div>
								<div>
									<span class="fw-600">Date : </span>
									<span class="fw-500">
										<!-- <ShowValue :object="milestone" object-key="date" label="Date"> </ShowValue> -->
										<span v-if="milestone" class="fw-500 no-wrap">
											{{ formatDate(milestone.date) }}
										</span>
										<span v-else>
											<em class="text-muted">No Invoice Date</em>
										</span>
									</span>
								</div>
								<div class="my-3">
									<span class="fw-600">Amount : </span>
									<span class="fw-500">
										<ShowPrice class="ml-2" :object="milestone" object-key="amount" label="Email">
										</ShowPrice>
									</span>
								</div>
							</div>
						</div>
						<div class="d-flex pb-3 w-33">
							<div>
								<div>
									<span class="fw-600">Start Date :</span>
									<span class="fw-500">
										<span v-if="project" class="fw-500 no-wrap">
											{{ formatDate(project.start_date) }}
										</span>
										<span v-else>
											<em class="text-muted">No End Date</em>
										</span>
									</span>
								</div>
								<div class="my-3">
									<span class="fw-600">End Date :</span>
									<span class="fw-500">
										<span v-if="project" class="fw-500 no-wrap">
											{{ formatDate(project.end_date) }}
										</span>
										<span v-else>
											<em class="text-muted">No End Date</em>
										</span>
									</span>
								</div>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="milestoneTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#deliverable">
						<inline-svg
							:fill="milestoneTab === 'deliverable' ? 'white' : 'Gray'"
							:src="$assetURL('media/custom-svg/deliverable1.svg')"
							height="15"
							width="15"
							class="mr-2"
						/>
						Deliverable</v-tab
					>
					<v-tab href="#proforma" v-if="getPermission('project-proforma-invoice::view')">
						<v-icon small left>mdi-receipt</v-icon> Proforma</v-tab
					>
					<v-tab href="#invoice" v-if="getPermission('project-invoice::view')">
						<inline-svg
							:fill="milestoneTab === 'invoice' ? 'white' : 'Gray'"
							:src="$assetURL('media/custom-svg/receipt_long.svg')"
							height="15"
							width="15"
							class="mr-2"
						/>Invoice</v-tab
					>
					<v-tab href="#payment">
						<inline-svg
							:fill="milestoneTab === 'payment' ? 'white' : 'Gray'"
							:src="$assetURL('media/custom-svg/payment.svg')"
							height="15"
							width="15"
							class="mr-2"
						/>
						Payment</v-tab
					>
					<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon>Files</v-tab>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon>Logs</v-tab>
				</v-tabs>
				<v-tabs-items v-model="milestoneTab">
					<v-tab-item value="deliverable">
						<div class="mx-4">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<v-layout class="border-bottom-light-grey min-height-57px">
									<v-flex class="font-level-3-bold my-auto d-flex justify-space-between align-center">
										<div class="d-flex">
											<!--begin::Svg Icon-->
											<inline-svg
												style="width: 20px; height: 20px"
												fill="#006fc8"
												:src="$assetURL('media/custom-svg/deliverable.svg')"
											/>
											<!--end ::Svg Icon-->
											<span class="ml-4">Deliverable</span>
											<div class="d-flex ml-4">
												<div>
													<v-chip class="pr-4 pl-2 py-3" color="orange" label outlined>
														<span class="font-level-2-bold">Total Items: </span
														><span class="font-level-1-bold pl-1"> {{ lineItemsList.length }}</span></v-chip
													>
												</div>
												<div class="mx-4">
													<v-chip class="pr-4 pl-2 py-3" color="green" label outlined>
														<span class="font-level-2-bold">Total Quantity to be delivered: </span
														><span class="font-level-1-bold pl-1">
															{{ total_quantity_to_be_delivered }}</span
														></v-chip
													>
												</div>
												<div>
													<v-chip class="pr-4 pl-2 py-3" color="blue darken-4" label outlined>
														<span class="font-level-2-bold">Total Gap: </span
														><span class="font-level-1-bold pl-1">{{ total_gap }}</span></v-chip
													>
												</div>
											</div>
										</div>
										<div class="w-150 mr-4 d-flex justify-space-between">
											<AutoCompleteInput
												v-if="quotationList"
												hide-details
												:items="quotationList"
												class="w-80 mb-3"
												id="quotation"
												placeholder="Quotation"
												item-text="barcode"
												@change="quotationFilterChange"
												item-value="id"
												v-model="quotationFilter"
											>
											</AutoCompleteInput>
											<v-btn
												@click="saveDeliverable"
												depressed
												tile
												:disabled="!showGenerateProformaBTn"
												class="blue darken-4 ml-4 my-auto"
												color="white--text"
											>
												Save
											</v-btn>
											<v-btn
												@click="generateProforma"
												depressed
												tile
												:disabled="!showGenerateProformaBTn"
												v-if="getPermission('project-proforma-invoice::create')"
												class="blue darken-4 ml-4 my-auto"
												color="white--text"
											>
												Generate Proforma Request
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
								<div class="taskListing" v-if="!pageLoading">
									<v-simple-table class="bt-table listing-table table-head-sticky" style="width: 100%">
										<thead>
											<tr>
												<th>
													<v-checkbox
														v-model="selectAllItems"
														dense
														hide-details
														v-if="lineItemsList.length"
														:disabled="!lineItemsList.length"
														class="mt-2 shrink"
														@change="selectAllLineItems"
													></v-checkbox>
												</th>
												<th>#</th>
												<th width="40px">Quote #</th>
												<th>Name</th>
												<th>Price</th>
												<th>UOM</th>
												<th>QTY</th>
												<th>QTY to be delivered</th>
												<th>Gap</th>
											</tr>
										</thead>
										<tbody v-if="lineItemsList.length">
											<tr class="cursor-pointer" v-for="(row, index) in lineItemsList" :key="index">
												<td width="80px">
													<v-checkbox dense v-model="row.is_check" @change="checkboxChange"></v-checkbox>
												</td>
												<td>{{ index + 1 }}</td>
												<td width="120px">
													<Chip
														tooltip
														tooltip-text="Quotation"
														small
														:text="row.quotation_barcode"
														color="blue darken-4"
													>
													</Chip>
													<div class="mt-1">
														<Chip
															tooltip
															tooltip-text="Status"
															small
															:text="row.quotation_status_value"
															:color="row.quotation_status_value === 'approved' ? 'green' : 'cyan'"
														>
														</Chip>
													</div>
												</td>
												<td width="120px">
													<ShowValue :object="row" object-key="line_item_name" label="Name"> </ShowValue>
												</td>
												<td>
													<ShowValue :object="row" object-key="line_item_total_formatted" label="Price">
													</ShowValue>
												</td>
												<td>
													<ShowValue :object="row" object-key="line_item_uom" label="uom"> </ShowValue>
												</td>
												<td>
													<v-tooltip top>
														<template v-slot:activator="{ on, attrs }">
															<span v-bind="attrs" v-on="on">{{ row.all_milestones_qty_gap }}</span>
														</template>
														<span>All Milestone Qty Gap</span>
													</v-tooltip>
													/<v-tooltip top>
														<template v-slot:activator="{ on, attrs }">
															<span v-bind="attrs" v-on="on">{{ row.line_item_quantity }}</span>
														</template>
														<span>Line Item Qty</span>
													</v-tooltip>
												</td>

												<td width="120px">
													<TextInput
														type="number"
														hideSpinButtons
														@keypress="qtyDeliveredKeyPress"
														hide-details
														id="qty_delivered"
														placeholder="QTY To Be Delivered "
														v-model.number="row.qty_to_be_delivered"
														@keyup="
															qtyToBeDeliveredKeydown(index, row.line_item_quantity, row.qty_to_be_delivered)
														"
													>
													</TextInput>
												</td>
												<td>
													<v-tooltip top>
														<template v-slot:activator="{ on, attrs }">
															<span v-bind="attrs" v-on="on">{{ row.gap }}</span>
														</template>
														<span>Gap</span> </v-tooltip
													>/<v-tooltip top>
														<template v-slot:activator="{ on, attrs }">
															<span v-if="row?.qty_to_be_delivered" v-bind="attrs" v-on="on">{{
																row?.qty_to_be_delivered
															}}</span>
															<span v-else v-bind="attrs" v-on="on">0</span>
														</template>
														<span>Qty to be delivered</span>
													</v-tooltip>
												</td>
											</tr>
										</tbody>
										<tfoot v-else>
											<tr>
												<td colspan="11">
													<p class="m-0 row-not-found text-center py-3">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no deliverable at the moment.
													</p>
												</td>
											</tr>
										</tfoot>
									</v-simple-table>
								</div>
								<div v-else class="mx-20 my-40 text-center">
									<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
								</div>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="proforma">
						<MilestoneProforma v-if="milestoneTab === 'proforma'" />
					</v-tab-item>
					<v-tab-item value="invoice">
						<MilestoneInvoice v-if="project" :project="project" />
					</v-tab-item>
					<v-tab-item value="payment">
						<MilestonePayment v-if="project" :project="project" />
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="milestoneTab == 'file'"
							class="mx-4"
							type-text="Project Files"
							type="project-milestone"
							:type-uuid="milestone.uuid"
						>
						</Files>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="milestoneTab == 'event'"
							class="mx-4"
							type-text="Customer"
							type="project-milestone"
							:type-uuid="milestone.uuid"
						></EventListing>
					</v-tab-item>
				</v-tabs-items>
			</div>

			<CreateMilestone
				v-if="milestoneEditDialog"
				:milestoneDetail="milestone"
				:addDialog="milestoneEditDialog"
				@close="milestoneEditDialog = false"
				:project="project"
				@success="milestoneUpdated"
			/>
			<Dialog :dialog="isDeliveryExistDialog" :dialog-width="600">
				<template v-slot:title>Confirmation</template>
				<template v-slot:body>
					<v-row class="delete-dialog">
						<v-col md="2" class="py-0 text-right my-auto">
							<span class="svg-icon svg-icon-lg delete-confirm-icon">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
								<!--end::Svg Icon-->
							</span>
						</v-col>
						<v-col md="10" class="py-0 my-auto">
							<p class="btx-p m-0 font-level-2">
								Is there any <span class="text-capitalize">delivery?</span>
								<!-- <span class="red--text lighten-1 font-level-1-bold"> #{{ deleteText }}</span> is irreversible
						and will also delete all associated records.<br />Are you sure about deleting it? -->
							</p>
							<p class="mt-2">
								<span class="red--text lighten-1 font-level-1-bold"
									>Note: If you want to generate proforma without delivery go to Proforma Section.</span
								>
							</p>
						</v-col>
						<!-- <v-col v-if="deleteNote" md="12" class="pb-0 my-auto">
					<em>Note: {{ deleteNote }}</em>
				</v-col> -->
					</v-row>
				</template>
				<template v-slot:action>
					<v-btn depressed tile v-on:click="isDeliveryExistDialog = false"> No, Close </v-btn>
					<slot name="extra-btn"></slot>
					<v-btn class="white--text" depressed color="blue darken-4" tile @click="goToProformaDialog">
						Yes!
					</v-btn>
				</template>
			</Dialog>
		</template>
		<CreateProformaDialog
			:project="project"
			:addDialog="isProformaDialogOpen"
			@close="(isProformaDialogOpen = false), (milestoneId = null)"
			:milestoneId="milestoneId"
			showLineItems
		/>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
// import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { getMilestoneByUUID, GetProject } from "@/core/lib/project.lib";
// import { getQuotationLineItemByQuotation } from "@/core/lib/quotation.lib.js";
import { isEmpty } from "lodash";
import Chip from "@/view/components/Chip";
import Dialog from "@/view/components/Dialog";
import ShowValue from "@/view/components/ShowValue";
import ShowPrice from "@/view/components/ShowPrice";
import Files from "@/view/components/Files";
import EventListing from "@/view/components/EventListing";
import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import CreateMilestone from "@/view/components/CreateMilestone";
import CreateProformaDialog from "@/view/components/CreateProforma";
import MilestonePayment from "@/view/components/MilestonePayment";
import MilestoneInvoice from "@/view/components/MilestoneInvoice";
import MilestoneProforma from "@/view/components/MilestoneProforma";
// import ShowBarcode from "@/view/components/ShowBarcode";
// import ApiService from "@/core/services/api.service";
import {
	UPDATE_QUOTATIONS_LINE_ITEMS,
	UPDATE_MILESTONE_DELIVERABLE,
	GET_MILESTONE_DELIVERABLE,
} from "@/core/services/store/proforma.module.js";
import { updateMilestoneStatus } from "@/core/lib/project.lib";
import { saveData } from "@/core/services/local.service";
export default {
	name: "milestone-detail",
	title: "Milestone Detail",
	mixins: [ListingMixin],
	components: {
		ShowValue,
		TextInput,
		ShowPrice,
		CreateMilestone,
		Chip,
		AutoCompleteInput,
		Dialog,
		CreateProformaDialog,
		MilestonePayment,
		MilestoneInvoice,
		MilestoneProforma,
		Files,
		EventListing,
		// ShowBarcode
	},
	data() {
		return {
			milestoneUUID: null,
			projectUUID: null,
			quotationFilter: null,
			barcode: null,
			pageLoading: true,
			customer: null,
			property: {},
			uuid: null,
			isDeliveryExistDialog: false,
			status_value: "draft",
			personModel: {},
			milestone: null,
			milestoneStatusList: [
				{
					name: "Draft",
					value: "draft",
					icons: "mdi-file",
					color: "orange",
					textColor: "orange--text",
				},
				{
					name: "Pending",
					value: "pending",
					icons: "mdi-cancel",
					color: "red",
					textColor: "red--text lighten-1",
				},
				{
					name: "Completed",
					value: "completed",
					icons: "mdi-check-all",
					color: "green",
					textColor: "green--text",
				},
			],
			lineItemsList: [],
			milestoneEditDialog: false,
			project: null,
			quotationList: null,
			selectedLineItems: [],
			checkboxValue: [],
			isProformaDialogOpen: false,
			milestoneId: null,
			showGenerateProformaBTn: false,
			selectAllItems: false,
		};
	},
	methods: {
		async getProject() {
			try {
				this.pageLoading = true;
				const project = await GetProject(this.projectUUID);
				this.project = project[0];
				this.$store.dispatch(SET_BREADCRUMB, [
					{ text: "Project", disabled: true },
					{ text: this.project?.barcode, disabled: true },
					{ text: "Milestone", disabled: true },
					{ text: this.milestone?.phase, disabled: true },
				]);
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		async getMilestone() {
			try {
				this.pageLoading = true;
				const milestone = await getMilestoneByUUID(this.milestoneUUID);
				this.milestone = milestone[0];
				this.quotationList = this.milestone.quotations;
				this.milestoneId = this.milestone.id;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		addPerson(person) {
			this.personDialog = true;
			this.personModel = new Object();
			if (person && !isEmpty(person)) {
				this.personModel = new Object({
					id: person.id || null,
					milestoneUUID: person.milestoneUUID || null,
					salutation: person.salutation || null,
					first_name: person.first_name || null,
					last_name: person.last_name || null,
					email: person.email || null,
					mobile: person.phone || null,
					display_name: person.display_name || null,
					designation: person.designation || null,
					primary: person.primary || null,
				});
			}
		},
		editMilestone() {
			this.$router.push({
				name: "milestone-create",
				params: { uuid: this.project.uuid },
				query: { mileUUID: this.milestone.uuid, t: new Date().getTime() },
			});
		},
		milestoneUpdated(data) {
			this.milestoneEditDialog = false;
			this.milestone = data;
		},
		generateProforma() {
			this.isDeliveryExistDialog = true;
		},
		goToProformaDialog() {
			const _this = this;
			const deliverable = [];
			const haveAtLeastOneQuantity = this.lineItemsList.some(
				(ele) => ele.is_check && ele.qty_to_be_delivered
			);
			if (!haveAtLeastOneQuantity) {
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Please enter at least one line item with quantity!" },
				]);
				// this.isDeliveryExistDialog = false
				return;
			}
			const atLeastOneLineItemSaved = this.lineItemsList.some((ele) => {
				return ele.is_check && ele.deliverable_id;
			});
			if (!atLeastOneLineItemSaved) {
				this.$store.commit(SET_ERROR, [{ model: true, message: "Please Save Line Items!" }]);
				// this.isDeliveryExistDialog = false
				return;
			}
			_this.lineItemsList.forEach((ele) => {
				if (ele.is_check && ele.qty_to_be_delivered) {
					deliverable.push(ele.deliverable_id);
					return ele;
				}
			});
			saveData("milestone_line_items", deliverable.join(","));
			_this.$router.push({
				name: "proforma-create",
				params: { uuid: _this.project.uuid },
				query: { mileUUID: _this.milestone.uuid, t: new Date().getTime() },
			});
		},
		async milestoneDeliverable(id) {
			const params = {
				quotation: id,
				filter_deliverable_ids: "",
			};
			this.pageLoading = true;
			try {
				const { deliverables } = await this.$store.dispatch(GET_MILESTONE_DELIVERABLE, {
					uuid: this.$route.query.mileUUID,
					params: params,
				});
				this.lineItemsList = deliverables;
				this.cloneLineItems = deliverables;
				this.lineItemsList.map((ele) => {
					ele.qtyCanDelivered = ele.qty_to_be_delivered + ele.all_milestones_total_qty_to_be_delivered;
					if (ele.deliverable_id) {
						ele.is_check = true;
					} else {
						ele.is_check = false;
					}
				});
				this.isAnyLineItemsSelected();
				this.isAllLineItemsSelected();
			} catch (error) {
				this.logError(error);
			} finally {
				this.pageLoading = false;
			}
		},
		quotationFilterChange(value) {
			if (!value) {
				this.lineItemsList = this.cloneLineItems;
				return;
			}
			this.pageLoading = true;
			const filterArray = this.cloneLineItems.filter((lineItem) => lineItem.quotation === value);
			this.lineItemsList = filterArray;
			this.pageLoading = false;
			// this.milestoneDeliverable(value)
		},
		qtyToBeDeliveredKeydown(index, qty, qty_to_delivered) {
			if (qty_to_delivered > qty) {
				setTimeout(() => {
					this.lineItemsList[index].qty_to_be_delivered = qty;
				}, 150);
			}
		},
		async saveDeliverable() {
			this.pageLoading = true;
			try {
				// const deliverable = []
				const checkDeliverable = this.lineItemsList.filter((ele) => {
					if (ele.is_check) {
						// deliverable.push(ele.line_item)
						return ele;
					}
				});
				// saveData("milestone_line_items", deliverable);
				const params = {
					deliverables: checkDeliverable,
				};
				await this.$store.dispatch(UPDATE_MILESTONE_DELIVERABLE, {
					uuid: this.$route.query.mileUUID,
					params: params,
				});
				this.$store.dispatch(UPDATE_QUOTATIONS_LINE_ITEMS, checkDeliverable);
				await this.milestoneDeliverable(null);
			} catch (error) {
				// this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				if (error?.data?.message) {
					this.$store.commit(SET_ERROR, [{ model: true, message: error.data.message }]);
				} else {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				}
			} finally {
				this.pageLoading = false;
			}
		},
		isAnyLineItemsSelected() {
			this.showGenerateProformaBTn = this.lineItemsList.some((ele) => ele.is_check);
		},
		isAllLineItemsSelected() {
			this.selectAllItems = this.lineItemsList.every((ele) => ele.is_check);
		},
		checkboxChange(value, index) {
			this.isAnyLineItemsSelected();
			this.isAllLineItemsSelected();
			if (value) {
				this.selectedLineItems.push(this.lineItemsList[index]);
			} else {
				this.selectedLineItems.splice(index, 1);
			}
		},
		selectAllLineItems() {
			this.lineItemsList.forEach((ele) => {
				ele.is_check = this.selectAllItems;
			});
			this.isAnyLineItemsSelected();
		},
		qtyDeliveredKeyPress($event) {
			if ($event.key === "e") {
				$event.preventDefault();
			}
		},
		async changingMilestoneStatus(value) {
			const params = {
				action: value,
				selected: [this.$route.query.mileUUID],
			};
			// console.log("params", params);
			try {
				await updateMilestoneStatus(params);
				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Milestone Status have successfully been updated." },
				]);
				await this.getMilestone();
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			}
		},
	},
	computed: {
		milestoneTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.milestoneTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "detail";
			},
		},
		total_quantity_to_be_delivered() {
			let qty_to_be_delivered = 0;
			this.lineItemsList.forEach((ele) => {
				if (ele.qty_to_be_delivered) {
					qty_to_be_delivered += ele.qty_to_be_delivered;
				}
			});
			return qty_to_be_delivered;
		},
		total_gap() {
			let gap = 0;
			this.lineItemsList.forEach((ele) => {
				if (ele.gap) {
					gap += ele.gap;
				}
			});
			return gap;
		},
	},

	async mounted() {
		this.internal = true;
		// this.$store.dispatch(SET_BREADCRUMB, [
		// 	{ text: "Project", disabled: true },
		// 	{ text: "Detail", disabled: true },
		// 	{ text: "Milestone", disabled: true },
		// 	// { text: "Detail", disabled: true },
		// ]);

		const { uuid } = this.$route.params;
		this.milestoneUUID = this.$route.query.mileUUID;
		this.projectUUID = uuid;

		if (!this.milestoneUUID) {
			this.goBack();
		}
		await this.getMilestone();
		await this.getProject();
		await this.milestoneDeliverable(null);
		// const params = {
		//     quotation: this.project.quotations[0].id,
		// };
		// const data = await getQuotationLineItemByQuotation(params);
		// this.lineItemsList = [...this.lineItemsList, ...data.line_items];
		// for (var i = 1; i <= this.lineItemsList.length; i++) {
		//     this.checkboxValue.push(false);
		// }
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
