<template>
	<div>
		<div class="mx-4">
			<div class="overflow-y" style="max-height: calc(100vh - 250px)">
				<v-layout class="border-bottom-light-grey min-height-57px">
					<v-flex class="font-level-3-bold my-auto d-flex justify-space-between">
						<!-- <span class="detail-svg-icon mr-2"> -->
						<!--begin::Svg Icon-->
						<div>
							<inline-svg
								style="width: 20px; height: 20px"
								fill="#006fc8"
								:src="$assetURL('media/custom-svg/payment2.svg')"
							/>
							<!--end ::Svg Icon-->
							<span class="ml-4">Payment</span>
						</div>
						<div>
							<v-btn
								@click="createPayment"
								color="blue darken-4 mr-4 white--text"
								class="ml-4"
								tile
								depressed
							>
								<v-icon>mdi-plus</v-icon>Create</v-btn
							>
						</div>
					</v-flex>
				</v-layout>
				<v-simple-table v-if="!pageLoading" class="bt-table listing-table table-head-sticky">
					<thead>
						<tr>
							<th width="10px">#</th>
							<th>Payment NO</th>
							<th>Invoice Title</th>
							<th>Date</th>
							<th>Payment Amount</th>
							<th>Invoice Amount</th>
							<!-- <th>Payment Date</th> -->
							<!-- <th>Proforma No</th> -->
							<th>Attachment</th>
							<th>Reference</th>
							<!-- <th>Status</th> -->
							<th>Actions</th>
						</tr>
					</thead>
					<tbody v-if="paymentList.length">
						<tr class="cursor-pointer" v-for="(row, index) in paymentList" :key="index + 1">
							<td width="10px">{{ index + 1 }}</td>
							<td>
								<Chip tooltip tooltip-text="Invoice No" :text="row.barcode" color="cyan"></Chip>
								<div>
									<Chip
										class="mt-1"
										tooltip
										tooltip-text="Invoice No"
										:text="row.invoice_barcode"
										color="cyan"
									></Chip>
								</div>
							</td>
							<td>
								<ShowValue :object="row" object-key="invoice_title" label="Invoice Tittle"> </ShowValue>
							</td>
							<td>
								<div>
									<span class="fw-500">Invoice Date : </span>
									<!-- <ShowValue :object="row" object-key="invoice_date" label="Invoice Date"></ShowValue> -->
									<span v-if="row?.invoice_date" class="fw-500 no-wrap">
										{{ formatDate(row.invoice_date) }}
									</span>
									<span v-else>
										<em class="text-muted">No Invoice date</em>
									</span>
								</div>
								<div>
									<span class="fw-500">Payment Date: </span>
									<!-- <ShowValue :object="row" object-key="payment_date" label="Date"> </ShowValue> -->
									<span v-if="row?.payment_date" class="fw-500 no-wrap">
										{{ formatDate(row.payment_date) }}
									</span>
									<span v-else>
										<em class="text-muted">No Payment date</em>
									</span>
								</div>
							</td>
							<td width="80px">
								<ShowPrice :object="row" object-key="payment_amount" label="Payment Amount"> </ShowPrice>
							</td>
							<td>
								<div>
									<span class="fw-500">Invoice Amount: </span>
									<ShowPrice :object="row" object-key="invoices_collected_amount" label="Invoice Amount">
									</ShowPrice>
								</div>
								<div>
									<span class="fw-500">Amount Received: </span>
									<ShowPrice :object="row" object-key="invoices_paid_amount" label="Amount Received">
									</ShowPrice>
								</div>
								<div>
									<span class="fw-500">Balance: </span>
									<ShowPrice :object="row" object-key="invoice_balance" label="Invoice Balance"> </ShowPrice>
								</div>
							</td>
							<!-- <td>
								<ShowValue :object="row" object-key="payment_date" label="Date"> </ShowValue>
							</td> -->
							<!-- <td>
								<ShowPrice :object="row" object-key="invoice_amount" label="Amount"> </ShowPrice>
							</td> -->
							<!-- <td>
								<Chip tooltip tooltip-text="Invoice No" :text="row.invoice_barcode" color="cyan"></Chip>
							</td> -->
							<td style="width: 100px" class="p-2 border-top-light-grey">
								<div v-if="row.attachment">
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<span v-on="on" v-bind="attrs">
												<v-btn
													fab
													icon
													small
													:loading="row.downloading"
													@click.stop.prevent="downloadAttachment(row.attachment.url)"
												>
													<v-icon color="" class="cursor-pointer">mdi-download-circle</v-icon>
												</v-btn>
											</span>
										</template>
										<span>Download Attachment</span>
									</v-tooltip>
									<span>{{ row.attachment.name }}</span>
								</div>
								<em v-else class="text-muted">no attachment</em>
							</td>
							<td>
								<ShowValue :object="row" object-key="reference" label="Reference"> </ShowValue>
							</td>
							<!-- <td>
								<Chip tooltip tooltip-text="Status #" :text="row.status_value" :color="
									row.status_value === 'paid'
										? 'green'
										: row.status_value === 'partial-paid'
											? 'blue'
											: 'red'
								">
								</Chip>
							</td> -->
							<td>
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											@click.prevent.stop="openDeleteDialog(row)"
											color="red lighten-1"
											v-bind="attrs"
											class="mx-2"
											v-on="on"
											dark
											fab
											x-small
										>
											<v-icon small dark> mdi-trash-can-outline</v-icon>
										</v-btn>
									</template>
									<span>Delete Payment</span>
								</v-tooltip>
							</td>
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="11">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no payment at the moment.
								</p>
							</td>
						</tr>
					</tfoot>
				</v-simple-table>
				<div v-else class="mx-20 my-40 text-center">
					<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
				</div>
			</div>
		</div>
		<DeleteTemplate
			type="Payment"
			v-on:close="(deleteDialog = false), (uuid = null)"
			:delete-dialog="deleteDialog"
			v-if="deleteDialog"
			:deleteText="singlePayment?.barcode"
			:deleteByUrl="false"
			v-on:success="deletePaymentConfirm"
		>
		</DeleteTemplate>
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue";
import ShowPrice from "@/view/components/ShowPrice";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import Chip from "@/view/components/Chip";
import { getPaymentByProjectMilestone, deletePayment } from "@/core/lib/project.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import ListingMixin from "@/core/mixins/listing.mixin";
export default {
	name: "milestone-detail",
	title: "Milestone Detail",
	mixins: [ListingMixin],
	data() {
		return {
			paymentList: [],
			deleteDialog: false,
			uuid: null,
		};
	},
	components: {
		ShowValue,
		Chip,
		ShowPrice,
		DeleteTemplate,
	},
	props: {
		project: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		openDeleteDialog(payment) {
			this.uuid = payment.uuid;
			this.singlePayment = payment;
			this.deleteDialog = true;
		},
		downloadAttachment(document_url) {
			window.open(document_url, "_blank");
		},
		createPayment() {
			this.$router.push({
				name: "create-payment",
				params: { uuid: this.project.uuid },
				query: { mileUUID: this.$route.query.mileUUID, t: new Date().getTime() },
			});
		},
		async initPayment() {
			const _this = this;
			try {
				this.pageLoading = true;
				const { payments } = await getPaymentByProjectMilestone(_this.$route.query.mileUUID);
				this.paymentList = payments;
				// console.log(data);
				// this.invoiceListing = invoices
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		async deletePaymentConfirm() {
			const _this = this;
			try {
				this.pageLoading = true;
				await deletePayment(_this.uuid);
				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: `Success ! Payment Successfully Deleted.` },
				]);
				await this.initPayment();
				this.deleteDialog = false;
				this.uuid = null;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},
	async mounted() {
		this.internal = true;
		await this.initPayment();
	},
};
</script>
