<template>
	<Dialog
		:dialog="addDialog"
		title="Proforma"
		current
		:dialogWidth="768"
		v-on:close="addDialog = false"
	>
		<template v-slot:title>Edit Milestone </template>
		<!-- <template v-slot:title v-else>Generate Proforma </template> -->
		<template v-slot:body>
			<table width="100%" class="detail-table scattered-table">
				<div>
					<v-form v-if="addDialog" ref="milestoneForm">
						<v-row>
							<v-col md="3">
								<label for="team" class="btx-label mt-2">Name</label>
							</v-col>
							<v-col md="9">
								<TextInput
									hide-details
									placeholder="Title"
									v-model="milestone.phase"
									:disabled="pageLoading"
									:loading="pageLoading"
								></TextInput>
							</v-col>
							<v-col md="3">
								<label for="description" class="btx-label mt-5">Description</label>
							</v-col>
							<v-col md="9">
								<TextAreaInput
									:disabled="pageLoading"
									:loading="pageLoading"
									id="description"
									placeholder="Description"
									v-model="milestone.description"
								>
								</TextAreaInput>
							</v-col>
							<v-col md="3">
								<label for="team" class="btx-label mt-4 required">Date</label>
							</v-col>
							<v-col md="3">
								<DatePicker
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="milestone_date"
									v-model="milestone.date"
									:rules="[vrules.required(milestone.date, 'Date')]"
									:class="{
										required: !milestone.date,
									}"
								></DatePicker>
							</v-col>
							<v-col class="text-right" md="3">
								<label for="team" class="btx-label mt-4">Reminder Date</label>
							</v-col>
							<v-col md="3">
								<DatePicker
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="task_start_date"
									v-model="milestone.reminder_date"
								></DatePicker>
							</v-col>

							<v-col md="3">
								<label for="team" class="btx-label mt-4 required">Payment Amount</label>
							</v-col>
							<v-col md="4" class="d-flex justify-center align-center">
								<TextInput
									hide-details
									type="number"
									placeholder="Amount"
									:rules="[vrules.required(milestone.payment_amount, 'Payment Amount')]"
									:class="{
										required: !milestone.payment_amount,
									}"
									v-model="milestone.payment_amount"
									:disabled="pageLoading"
									:loading="pageLoading"
								>
								</TextInput>
							</v-col>
							<v-col class="text-right" md="2">
								<label for="team" class="btx-label mt-4 required">Percentage</label>
							</v-col>
							<v-col md="3">
								<TextInput
									hide-details
									placeholder="Percentage"
									vMask="###"
									v-model="milestone.percentage"
									:disabled="pageLoading"
									:loading="pageLoading"
									:rules="[
										vrules.required(milestone.percentage, 'Percentage'),
										vrules.lessThan(milestone.percentage, 'Percentage', 100),
									]"
									:class="{ required: !milestone.percentage }"
								>
								</TextInput>
							</v-col>
							<v-col md="3">
								<label for="team" class="btx-label mt-4">Attachment</label>
							</v-col>
							<v-col md="9">
								<FileUpload v-model="milestone.attachment"></FileUpload>
							</v-col>
							<v-col md="3">
								<label for="team" class="btx-label mt-4">Amount Raised</label>
							</v-col>
							<v-col md="3">
								<TextInput
									hide-details
									type="number"
									placeholder="Amount Raised"
									v-model.number="milestone.amount_raised"
									:disabled="pageLoading"
									:loading="pageLoading"
								>
								</TextInput>
							</v-col>
							<v-col class="text-right" md="3">
								<label for="team" class="btx-label mt-4">Invoice Date</label>
							</v-col>
							<v-col md="3">
								<DatePicker
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="task_start_date"
									v-model="milestone.invoice_date"
								></DatePicker>
							</v-col>
							<v-col md="3">
								<label for="team" class="btx-label mt-4">Invoice Raised</label>
							</v-col>
							<v-col md="3">
								<TextInput
									hide-details
									type="number"
									placeholder="Invoice Raised"
									v-model.number="milestone.invoice_raised"
									:disabled="pageLoading"
									:loading="pageLoading"
								>
								</TextInput>
							</v-col>
							<v-col class="text-right" md="3">
								<label for="team" class="btx-label mt-4">Payment Received</label>
							</v-col>
							<v-col md="3">
								<TextInput
									hide-details
									type="number"
									placeholder="Payment Received"
									v-model.number="milestone.payment_received"
									:disabled="pageLoading"
									:loading="pageLoading"
								>
								</TextInput>
							</v-col>
							<v-col md="3">
								<label for="team" class="btx-label mt-4">Remaining</label>
							</v-col>
							<v-col md="3">
								<TextInput
									hide-details
									type="number"
									placeholder="Amount"
									v-model.number="milestone.payment_remaining"
									:disabled="pageLoading"
									:loading="pageLoading"
								></TextInput>
							</v-col>
						</v-row>
					</v-form>
				</div>
			</table>
		</template>
		<template v-slot:action>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', false)"> Close </v-btn>
			<v-btn @click="editMileStone()" class="white--text" depressed color="blue darken-4" tile>
				Save
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
// import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import FileUpload from "@/view/components/FileUpload";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import { updateMilestone } from "@/core/lib/project.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
export default {
	name: "asset-internal-listing",
	title: "Listing Internal Asset",
	components: {
		Dialog,
		TextInput,
		FileUpload,
		DatePicker,
		TextAreaInput,
	},
	props: {
		addDialog: {
			type: Boolean,
			default: false,
		},
		project: {
			type: Object,
			default: () => {},
		},
		milestoneDetail: {
			ype: Object,
			default: () => {},
		},
		milestoneUUID: {
			type: String,
		},
	},
	data() {
		return {
			pageLoading: false,
			uuid: null,
			milestone: {
				date: null,
				phase: null,
				attachment: null,
				percentage: null,
				description: null,
				invoice_date: null,
				reminder_date: null,
				amount_raised: null,
				payment_amount: null,
				invoice_raised: null,
				payment_received: null,
				payment_remaining: null,
			},
		};
	},
	computed: {
		...mapGetters(["errors"]),
	},
	methods: {
		async editMileStone() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.milestoneForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.milestoneForm.validate()) {
				return false;
			}
			try {
				const formData = {
					project: _this.project.id,
					phase: _this.milestone.phase,
					date: _this.milestone.date,
					description: _this.milestone.description,
					amount: _this.milestone.payment_amount,
					progress: _this.milestone.percentage,
					reminder_date: _this.milestone.reminder_date,
					files: _this.milestone.attachment,
					amount_raised: _this.milestone.amount_raised,
					invoice_date: _this.milestone.invoice_date,
					invoice_raised: _this.milestone.invoice_raised,
					payment_received: _this.milestone.payment_received,
					remaining: _this.milestone.payment_remaining,
				};
				_this.pageLoading = true;
				const data = await updateMilestone(_this.milestoneDetail.uuid, formData);
				_this.$emit("success", data);
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Milestone has been update." },
				]);
				_this.uuid = null;
				_this.milestone = {
					project: null,
					phase: null,
					date: null,
					description: null,
					amount: null,
					progress: null,
					reminder_date: null,
					files: null,
					amount_raised: null,
					invoice_raised: null,
					payment_received: null,
					remaining: null,
				};
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
	},
	mounted() {
		this.milestone = {
			date: this.milestoneDetail.date,
			phase: this.milestoneDetail.phase,
			payment_amount: this.milestoneDetail.amount,
			percentage: this.milestoneDetail.progress ? this.milestoneDetail.progress : null,
			description: this.milestoneDetail.description,
			invoice_date: this.milestoneDetail.invoice_date,
			reminder_date: this.milestoneDetail.reminder_date,
			amount_raised: this.milestoneDetail.amount_raised ? this.milestoneDetail.amount_raised : null,
			invoice_raised: this.milestoneDetail.invoice_raised ? this.milestoneDetail.invoice_raised : null,
			payment_received: this.milestoneDetail.payment_received
				? this.milestoneDetail.payment_received
				: null,
			payment_remaining: this.milestoneDetail.remaining ? this.milestoneDetail.remaining : null,
		};
	},
};
</script>
